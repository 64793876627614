<!-- 预约页 -->
<template>
  <div v-if="teachers && teachers.length" class="reserve-detail">
    <!-- <div class="reserve-detail-head">
      <img
        @click="handleBack"
        src="@/assets/images/back.png"
        style="width: 20px; height:20px"
      />
    </div> -->
    <div class="reserve-detail-teachers-wrp">
      <div class="reserve-detail-teachers">
        <div
          :class="{ teacher: true, 'teacher-select': activeIdx === idx }"
          v-for="(teacher, idx) in teachers"
          :key="teacher.id"
          @click="selectTeacher(idx)"
        >
          <div class="teacher-avatar">
            <img
              v-if="teacher.avatar && teacher.avatar.length > 2"
              :src="teacher.avatar"
            />
            <img v-else src="@/assets/images/avator.jpg" />
          </div>
          <div class="teacher-name">{{ teacher.teacherName }}</div>
        </div>
      </div>
    </div>

    <div class="reserve-detail-divide"></div>

    <div v-if="activeTeacher" class="reserve-detail-introduction">
      <div class="head">导师简介</div>
      <div class="detail">{{ activeTeacher.introduction }}</div>
    </div>

    <div class="reserve-detail-divide-sliver"></div>

    <div v-if="activeTeacher" lass="reserve-detail-times">
      <div class="reserve-detail-times-tab">
        <div class="item" @click="handleTime(0)">
          本周空闲时间
          <div v-if="activeWeek === 0" class="active-bar"></div>
        </div>
        <div class="item" @click="handleTime(1)">
          下周空闲时间
          <div v-if="activeWeek === 1" class="active-bar"></div>
        </div>
      </div>
      <div
        v-if="
          activeTeacher.personWorkSwitch !== 2 && activeTeacher.workSwitch !== 2
        "
        class="reserve-detail-times-content"
      >
        <div class="calendar">
          <div class="row">
            <div
              class="col"
              style="flex-direction: column; flex: 1"
              v-for="(item, index) in freeTimes"
              :key="`${item.name}`"
              :class="{ active: activeDateIdx === index }"
              @click="handleDate(index)"
            >
              <div class="day">
                <span>{{ item.name }}</span>
              </div>
              <div class="date">
                {{ item.day }}
              </div>
            </div>
          </div>
        </div>
        <div class="date-container">
          <div
            v-if="
              freeTimes[activeDateIdx].config &&
              freeTimes[activeDateIdx].config.length
            "
            class="date-detail"
          >
            <div
              :class="{
                date: true,
                'date-active': activeTimeIdx === idx,
                'disable-date': !date.isAvailable,
              }"
              v-for="(date, idx) in freeTimes[activeDateIdx].config"
              :key="idx"
              @click="date.isAvailable && selectDate(idx, date)"
            >
              {{ date.startHour }} ~ {{ date.endHour }}
            </div>
          </div>
          <div v-else class="date-detail-empty">暂无时间安排～</div>
        </div>
      </div>
      <div v-else class="reserve-detail-times-empty">暂无时间安排～</div>
      <div class="reserve-detail-btn" @click="handleReserve">预约上课</div>
    </div>
  </div>
  <div v-else class="reserve-detail">
    <!-- <div class="reserve-detail-head">
      <img
        @click="handleBack"
        src="@/assets/images/back.png"
        style="width: 20px; height:20px"
      />
    </div> -->
    <div class="reserve-empty">
      <img src="@/assets/images/emptyTeacher.png" style="width: 125px" />
      <div class="text">暂无导师提供排期</div>
    </div>
  </div>
</template>

<script>
import {
  getReserveTeacher,
  saveReservePlan,
  checkReserveTime,
} from "@/api/learner";
import moment from "moment";

export default {
  data() {
    return {
      id: 0,
      source: 0,
      profession: 0,
      courseType: 0,
      teachers: [],
      activeIdx: 0,
      activeTeacher: null,
      activeWeek: 0,
      activeDate: null,
      activeDateIdx: 0,
      activeTimeIdx: 0,
      isNextWeek: false,
      duration: 60,
      freeTimes: [
        {
          day: 0,
          dayForWeek: 1,
          name: "一",
          config: [],
        },
        {
          day: 0,
          dayForWeek: 2,
          name: "二",
          config: [],
        },
        {
          day: 0,
          dayForWeek: 3,
          name: "三",
          config: [],
        },
        {
          day: 0,
          dayForWeek: 4,
          name: "四",
          config: [],
        },
        {
          day: 0,
          dayForWeek: 5,
          name: "五",
          config: [],
        },
        {
          day: 0,
          dayForWeek: 6,
          name: "六",
          config: [],
        },
        {
          day: 0,
          dayForWeek: 7,
          name: "日",
          config: [],
        },
      ],
    };
  },
  async mounted() {
    console.log(this.freeTimes);
    if (this.$route.query) {
      const { id, source, profession, courseType, teacherId, duration } =
        this.$route.query;
      this.id = id;
      this.source = source;
      this.profession = profession;
      this.courseType = courseType;
      this.duration = duration;

      const ids = teacherId && teacherId.split(",");
      for (let i = 0; i < ids.length; i++) {
        const data = await this.getReserveTeacher({
          source,
          profession,
          courseType,
          duration,
          teacherId: ids[i],
        });
        this.teachers = this.teachers.concat(
          data.filter((item) => item.timeConfigList.length)
        );
      }
      console.log(this.teachers[0]);
      this.activeTeacher = this.teachers[0];
      this.formatTimes();
      this.formatFreeTimes();
      this.setActiveDateIdx();
      this.activeDate = this.freeTimes[this.activeDateIdx].config[0];
      this.handleDate(this.activeDateIdx);
    }
  },
  created() {
    document.title = this.$route.query?.lessonName;
  },
  // 方法集合
  methods: {
    getReserveTeacher,
    handleBack() {
      this.$router.back();
    },
    handleReserve() {
      if (!this.activeDate) {
        this.$toast("请选择一个开始时间");
        return;
      }
      let weekOfday = Number(moment().format("E"));
      for (let i = 0; i < this.freeTimes.length; i++) {
        const time = this.isNextWeek
          ? moment()
              .add(7, "days")
              .subtract(weekOfday - i - 1, "d")
          : moment().subtract(weekOfday - i - 1, "d");
        const timeStr = time.format("YYYY-MM-DD");
        if (time.format("MM.DD") === this.freeTimes[this.activeDateIdx].day) {
          const startTime = moment(
            `${timeStr} ${this.activeDate.startHour}`
          ).format("YYYY-MM-DD HH:mm:ss");
          saveReservePlan({
            groupId: Number(this.id),
            teacherId: this.activeTeacher.id,
            startTime,
          })
            .then(() => {
              this.$toast("已发送申请，待班主任确认");
              setTimeout(() => {
                this.$router.back();
              }, 300);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    },
    async selectTeacher(idx) {
      this.activeIdx = idx;
      this.activeTeacher = this.teachers[this.activeIdx];
      // 重置本周空闲时间 tab
      this.activeWeek = 0;
      this.isNextWeek = false;
      this.clearConfig();
      // 重新计算日期 MM.DD
      this.formatTimes(this.isNextWeek);
      // 重置为激活为当天
      this.setActiveDateIdx();
      this.formatFreeTimes();
      // 查询当天预约情况
      await this.validateTime(this.isNextWeek);
    },
    // handle this week / next week
    async handleTime(idx) {
      this.activeWeek = idx;
      this.isNextWeek = Boolean(idx);
      // 重新计算日期 MM.DD
      this.formatTimes(this.isNextWeek);
      await this.validateTime(this.isNextWeek);
    },
    // handle day in a week
    async handleDate(idx) {
      this.activeDateIdx = idx;
      this.activeTimeIdx = 0;
      this.activeDate = null;
      await this.validateTime(this.isNextWeek);
    },
    // handle detail date in a day
    selectDate(idx, date) {
      this.activeTimeIdx = idx;
      this.activeDate = date;
    },
    formatTimes(isNextWeek = false) {
      const weeks = this.getWeekTime(isNextWeek);
      console.log(this.freeTimes, "formatTimes");
      let times = this.freeTimes.map((time, idx) => {
        return {
          ...time,
          day: weeks[idx].day,
        };
      });

      this.freeTimes = times;
    },
    clearConfig() {
      let times = this.freeTimes.map((time) => {
        return {
          ...time,
          config: [],
        };
      });

      this.freeTimes = times;
    },
    formatFreeTimes() {
      if (this.activeTeacher && this.activeTeacher.timeConfigList) {
        this.activeTeacher.timeConfigList.forEach((item) => {
          if (this.freeTimes[item.dayForWeek - 1]) {
            this.freeTimes[item.dayForWeek - 1].config.push(item);
          }
        });
      }
    },
    getWeekTime(isNextWeek = false) {
      let weekOfday = Number(moment().format("E"));
      let arr = [];
      for (let i = 0; i < this.freeTimes.length; i++) {
        arr.push({
          day: moment(isNextWeek ? moment().add(7, "days") : moment())
            .subtract(weekOfday - i - 1, "d")
            .format("MM.DD"),
        });
      }
      return arr;
    },
    setActiveDateIdx() {
      const weeks = this.getWeekTime();
      weeks.map((item, idx) => {
        if (item.day === moment().format("MM.DD")) {
          this.activeDateIdx = idx;
        }
      });
    },
    async validateTime(isNextWeek = false) {
      let weekOfday = Number(moment().format("E"));
      for (let i = 0; i < this.freeTimes.length; i++) {
        const time = isNextWeek
          ? moment()
              .add(7, "days")
              .subtract(weekOfday - i - 1, "d")
          : moment().subtract(weekOfday - i - 1, "d");
        const timeStr = time.format("YYYY-MM-DD");
        if (time.format("MM.DD") === this.freeTimes[this.activeDateIdx].day) {
          const params = this.freeTimes[this.activeDateIdx].config.map(
            (item) => {
              const startTime = moment(`${timeStr} ${item.startHour}`).format(
                "YYYY-MM-DD HH:mm:ss"
              );
              const endTime = moment(`${timeStr} ${item.endHour}`).format(
                "YYYY-MM-DD HH:mm:ss"
              );
              return {
                startTime,
                endTime,
              };
            }
          );
          const res = await checkReserveTime({
            teacherId: this.activeTeacher.id,
            reserveTimeVoList: params,
          });
          let firstTime = true;
          this.freeTimes[this.activeDateIdx].config = this.freeTimes[
            this.activeDateIdx
          ].config.map((item, idx) => {
            const newItem = { ...item };
            newItem.isAvailable =
              res && res.length ? res[idx].isAvailable : false;
            if (newItem.isAvailable && firstTime) {
              this.activeTimeIdx = idx;
              this.activeDate = item;
              firstTime = false;
            }
            return newItem;
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.reserve-detail {
  height: 100vh;
  background: #fff;
  &-head {
    padding: 0px 5px 5px 20px;
  }
  &-teachers {
    display: flex;
    width: 100%;
    overflow: auto;
    bottom: -9px;
    position: absolute;
    overflow-x: scroll;
    &-wrp {
      position: relative;
      width: 100vw;
      height: 76px;
      padding-bottom: 8px;
      overflow: hidden;
    }
    .teacher {
      width: 52px;
      padding: 0px 12px 10px 12px;
      text-align: center;
      &-avatar {
        padding: 2px 0 0 0;
        img {
          width: 52px;
          height: 52px;
          border: 2px solid #ffffff;
          border-radius: 50%;
        }
      }
      &-name {
        color: #393939;
        padding-top: 7px;
        font-size: 12px;
        width: 50px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .teacher:first-child {
      padding-left: 23px;
    }
    .teacher:last-child {
      padding-right: 23px;
    }
    .teacher-select {
      .teacher-avatar {
        img {
          border: 2px solid #f52c41;
        }
      }
      .teacher-name {
        color: #f52c41;
      }
    }
  }
  .date-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .date-detail {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 10px;
      text-align: center;
      font-size: 12px;
      padding-bottom: 88px;
      .date {
        width: fit-content;
        white-space: nowrap;
        background: #f7f7f7;
        border-radius: 6px;
        border: 1px solid #f7f7f7;
        padding: 24px 9px;
      }
      .date-active {
        background: #fff7f9;
        border-radius: 6px;
        border: 1px solid #f52c41;
        color: #f52c41;
      }
      .disable-date {
        color: #b8babe;
        background: #f7f7f7;
        border: 1px solid #f7f7f7;
      }
      &-empty {
        padding: 10px;
        font-size: 16px;
        color: #c6c6c6;
      }
    }
  }
  &-divide {
    width: 100%;
    height: 14px;
    border-bottom: 1px solid #f3f3f3;
  }
  &-divide-sliver {
    width: 100%;
    height: 10px;
    background: #f3f3f3;
  }
  &-introduction {
    padding: 16px;
    height: 220px;
    .head {
      color: #444f6a;
      font-size: 16px;
    }
    .detail {
      padding-top: 12px;
      color: #4a4a4a;
      line-height: 24px;
      letter-spacing: 1px;
      font-size: 14px;
      white-space: pre-wrap;
      max-height: 190px;
      overflow-y: scroll;
    }
    .detail::-webkit-scrollbar {
      width: 0 !important;
    }
  }
  &-times {
    &-tab {
      font-size: 16px;
      color: #393939;
      display: flex;
      padding: 16px 0;
      .item {
        width: 50%;
        text-align: center;
        .active-bar {
          width: 30px;
          height: 2px;
          border-radius: 2px;
          background: #f52c41;
          margin: 6px auto;
        }
      }
    }
    &-content {
      .calendar {
      }
      .detail {
      }
    }
    &-empty {
      font-size: 16px;
      color: #c6c6c6;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: calc(100vh - 500px);
    }
  }
  &-btn {
    line-height: 50px;
    color: #fff;
    width: calc(100% - 20px);
    height: 50px;
    background: #f52c41;
    border-radius: 4px;
    position: fixed;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 16px;
    text-align: center;
  }
  .reserve-empty {
    width: 100%;
    font-size: 16px;
    color: #c6c6c6;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.calendar {
  padding: 10px 0 20px 0;
  border-bottom: 1px solid #f3f3f3;
  margin-bottom: 20px;
  .row {
    display: flex;
    padding: 0 10px;
  }
  .col {
    text-align: center;
  }
  .day {
    font-size: 14px;
    color: #797c80;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 50%;
    margin: 0 auto;
  }
  .date {
    font-size: 12px;
    color: #393939;
    line-height: 12px;
    padding-top: 8px;
  }
  .active {
    .day {
      background: #f52c41;
      color: #fff;
    }
  }
}
@media screen and (max-width: 480px) {
  .date-detail {
    grid-template-columns: repeat(3, 1fr) !important;
  }
}
</style>
